define(['app'], (app) => {
  return () => {
    const component = {};
    const _config = {
      hideDelay: 10000,
      fadeClass: 'handoverMessage-fadeOut',
    };

    const _init = (element) => {
      component.element = element;
      app.publish('accessibility/announce', 'assertive', component.element.innerText);
      window.setTimeout(component.fadeOutMessage, component.config.hideDelay);
      component.element.addEventListener('transitionend', component.removeMessage);
    };

    const _fadeOutMessage = () => component.element.classList.add(component.config.fadeClass);
    const _removeMessage = () => component.element.remove();

    component.config = _config;
    component.init = _init;
    component.removeMessage = _removeMessage;
    component.fadeOutMessage = _fadeOutMessage;

    return component;
  };
});
